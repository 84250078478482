body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* div {
  margin: 0.5em
}

#root {
  margin: -0.5em
} */

.MuiFormControl-root {
  width: 100%;
}

.notistack-SnackbarContainer.z-alert {
  z-index: 10000;
}

.leaflet-marker-icon {
  z-index: 10 !important;
}

#notistack-snackbar {
  z-index: 20000;
}

.spinner {
  margin: 20px auto 20px auto;
  position: relative;
  -webkit-animation: spin 1s infinite ease-in;
  animation: spin 0.7s infinite linear;
  height: 65px;
  width: 40px;
  background: url(https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Hammer_-_Noun_project_1306.svg/1088px-Hammer_-_Noun_project_1306.svg.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(55deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
